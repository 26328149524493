<template>
  <div class="payment-wrap">
<!--    <div v-if="showLoading" class="payment-loading-wrap">-->
<!--      <van-loading color="#fff" size="48" vertical>{{ t('加载中') }}...</van-loading>-->
<!--    </div>-->

    <div class="payment-main-wrap">
      <div v-if="!paymentList.length" class="add-payment-box">
        <div class="title">{{ t('添加收款信息') }}</div>
        <i v-html="uploadIcon" @click="openAdd" />
        <span>{{ t('添加收付款方式') }}</span>
      </div>

      <div v-else class="payment-item-wrap">
        <div class="title">{{ t('收款信息') }}</div>

        <div v-for="(item,index) in paymentList" :key="index" class="payment-item-box">

          <template v-if="item.type == 1">
            <van-image v-if="locale === 'vi'" :src="themeImgs.commonPaymentIconVn1" class="type-img"/>
            <van-image v-else :src="themeImgs.commonPaymentIcon1" class="type-img"/>
            <div class="payment-detail-wrap">
              <div class="payment-detail-box">
                <span class="label">{{ t('支付宝名') }}：</span>
                <span class="text">{{ item.name }}</span>
              </div>
              <div class="payment-detail-box">
                <span class="label">{{ t('支付宝账号') }}：</span>
                <span class="text">{{ item.card }}</span>
              </div>
              <div class="payment-detail-box">
                <span class="label">{{ t('查看二维码') }}：</span>
                <span class="text" @click="handleOpenCodeDialog(item)">{{ t('查看二维码') }}
                  <van-image :src="themeImgs.paymentDoublArrowIcon"
                             class="type-img"/>
                </span>
              </div>
            </div>
            <div v-if="item.status==2" :style="`background:url(${themeImgs.paymentStatusBgImg})`"
                 class="payment-status">{{ t('禁用') }}
            </div>
            <div v-if="item.status==3" :style="`background:url(${themeImgs.paymentStatusBgImg})`"
                 class="payment-status sys-disabled">{{ t('系统禁用') }}
            </div>
          </template>

          <template v-if="item.type == 2">
            <van-image v-if="locale === 'vi'" :src="themeImgs.commonPaymentIconVn2" class="type-img"/>
            <van-image v-else :src="themeImgs.commonPaymentIcon2" class="type-img"/>
            <div class="payment-detail-wrap">
              <div class="payment-detail-box">
                <span class="label">{{ t('微信名') }}：</span>
                <span class="text">{{ item.name }}</span>
              </div>
              <div class="payment-detail-box">
                <span class="label">{{ t('查看二维码') }}：</span>
                <span class="text" @click="handleOpenCodeDialog(item)">{{ t('查看二维码') }}
                  <van-image :src="themeImgs.paymentDoublArrowIcon"
                             class="type-img"/>
                </span>
              </div>
            </div>
            <div v-if="item.status==2" :style="`background:url(${themeImgs.paymentStatusBgImg})`"
                 class="payment-status">{{ t('禁用') }}
            </div>
            <div v-if="item.status==3" :style="`background:url(${themeImgs.paymentStatusBgImg})`"
                 class="payment-status sys-disabled">{{ t('系统禁用') }}
            </div>
          </template>

          <template v-if="item.type == 3">
            <van-image v-if="locale === 'vi'" :src="themeImgs.commonPaymentIconVn3" class="type-img"/>
            <van-image v-else :src="themeImgs.commonPaymentIcon3" class="type-img"/>
            <div class="payment-detail-wrap">
              <div class="payment-detail-box">
                <span class="label">{{ t('银行卡号') }}：</span>
                <span class="text">{{ item.card }}</span>
              </div>
              <div class="payment-detail-box">
                <span class="label">{{ t('银行名') }}：</span>
                <span class="text">{{ item.bank_name }}</span>
              </div>
            </div>
            <div v-if="item.status==2" :style="`background:url(${themeImgs.paymentStatusBgImg})`"
                 class="payment-status">{{ t('禁用') }}
            </div>
            <div v-if="item.status==3" :style="`background:url(${themeImgs.paymentStatusBgImg})`"
                 class="payment-status sys-disabled">{{ t('系统禁用') }}
            </div>
          </template>
        </div>
        <div v-if="paymentList.length<3" class="add-btn-box">
          <van-button block @click="openAdd">
            <span>{{ t('添加收付款方式') }}</span>
            <i v-html="uploadIcon" />
          </van-button>
        </div>
      </div>
    </div>

    <!-- 二维码弹框 -->
    <van-dialog v-model:show="showCodeDialog"
                closeOnClickOverlay
                :title="t('二维码')"
                :show-confirm-button="false"
                teleport="body"
                class="payment-check-qrcode-dialog"
    >
      <van-image class="close-icon" :src="themeImgs.commonCloseIcon"
                 @click="showCodeDialog = false" />

      <div :style="`background:url(${themeImgs.commonCodeBgImg})`" class="code-box">
        <van-image :src="dialogCodeImg"/>
        <div class="name">{{ dialogCodeName }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue'
import {getPaymentList} from '@/api/api'
import {useRouter} from "vue-router"
import {Toast} from 'vant'
import {useStore} from 'vuex'
import { uploadIcon } from '@/assets/images/svg'
import { useI18n } from 'vue-i18n'

export default {
  emits: ['update:showAdd'],
  setup(props, ctx) {

    const { t, locale } = useI18n()
    const store = useStore()
    const $router = useRouter()
    // 是否显示加载
    const showLoading = ref(true)
    // 存储收付款列表数据
    const paymentList = ref([])
    // 获取收付款列表数据
    const handleGetPaymentList = () => {
      getPaymentList().then(res => {
        if (res.result == 1032) {
          // $router.push('/home')
          // store.commit('setPaymentDialogFlag', false)
          return Toast.fail(res.msg)
        } else if (res.result == 200000) {
          const array = []
          for (let key in res.data) {
            array.push(res.data[key])
          }
          array.sort((a, b) => b.type - a.type) // 银行卡放第一个
          paymentList.value = array

          showLoading.value = false
        } else {
          showLoading.value = false
        }
      })
    }
    onMounted(() => {
      handleGetPaymentList()
    })
    // 是否显示二维码弹框
    const showCodeDialog = ref(false)
    // 存储二维码弹框所要展示的二维码
    const dialogCodeImg = ref('')
    const dialogCodeName = ref('')

    // 打开二维码弹框
    const handleOpenCodeDialog = (item) => {
      showCodeDialog.value = true
      dialogCodeImg.value = item.bank_name
      dialogCodeName.value = item.name
    }
    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)

    const openAdd = () => {
      ctx.emit('update:showAdd', true)
    }

    return {
      paymentList,
      showLoading,
      showCodeDialog,
      dialogCodeImg,
      handleOpenCodeDialog,
      themeImgs,
      uploadIcon,
      dialogCodeName,
      openAdd,
      t,
      locale
    }
  }
}
</script>

<style lang="less" scoped>
.payment-wrap {
  .payment-loading-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 46px);
    padding: 24px 16px;
    margin-top: 46px;
    //background: var(--mainBgColor);
  }

  .payment-main-wrap {
    width: 100%;
    //background: var(--mainBgColor);
    overflow-y: scroll;

    .title {
      padding-top: 23px;
      font-size: 17px;
      line-height: 22px;
      color: #000;
      font-weight: 500;
      font-family: PingFang SC;
      text-align: center;
    }

    .add-payment-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-family: PingFang SC;

      i {
        width: 72px;
        height: 72px;
        margin-bottom: 39px;
        margin-top: 81px;

        ::v-deep svg {
          width: 100%;
          height: 100%;

          circle {
            fill: #FDD431;
          }
        }
      }

      span {
        display: inline-block;
        padding-bottom: 127px;
        font-size: 18px;
        line-height: 25px;
        color: #666;
      }
    }

    .payment-item-wrap {
      padding: 0 15px;

      .title {
        margin-bottom: 19px;
      }

      .payment-item-box {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        padding: 12px 18px;
        //background: var(--boxBgColor);
        background: #F4F4F4;
        border-radius: 8px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .type-img {
          width: 24px;
          height: 24px;
        }

        .payment-detail-wrap {
          margin-left: 14px;

          .payment-detail-box {
            display: flex;
            align-items: center;
            margin-bottom: 14px;

            &:last-child {
              margin-bottom: 0;
            }

            span {
              display: flex;
              align-items: center;
              font-family: PingFang SC;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              //color: var(--textCommonColor);
              color: #000;

              &.text {
                //color: var(--textColor);
                color: #2EBD85;
              }

              .van-image {
                width: 16px;
                height: 16px;
                margin-left: 4px;
              }
            }

          }
        }

        .payment-status {
          position: absolute;
          top: 11px;
          right: 18px;
          width: 44px;
          height: 22px;
          line-height: 22px;
          background-size: 100% 100% !important;
          color: #fff;
          text-align: center;

          &.sys-disabled {
            width: 70px;
          }
        }
      }

      .add-btn-box {
        height: 44px;
        margin-top: 20px;
        border-radius: 8px;

        :deep(.van-button) {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background: #2EBD85;

          .van-button__text {
            display: flex;
            align-items: center;

            span {
              margin-right: 6px;
              font-family: SF Pro Display;
              font-size: 16px;
              font-weight: 500;
              color: #FFF;
            }

            i {
              width: 24px;
              height: 24px;

              svg {
                width: 100%;
                height: 100%;

                circle {
                  fill: rgba(255, 255, 255, .3);
                }
              }

            }
          }

        }
      }
    }
  }

  @media only screen and (min-width: 750px) {
    .payment-main-wrap {
      width: 375px;
    }
  }
}
</style>

<style lang="less">
.payment-check-qrcode-dialog {
  width: 327px;

  .van-dialog__header {
    padding: 21px 0 19px;
    //color: var(--textColor1);
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #2EBD85;
  }

  .close-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 24px;
    height: 24px;
  }

  .code-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 275px;
    background-size: 100% 100% !important;

    .van-image {
      width: 158px;
      height: 158px;
      margin-bottom: 20px;
      border-radius: 8px;
    }

    .name {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: center;
      color: rgba(53, 53, 53, 0.7);
    }
  }
}
</style>
