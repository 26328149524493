<template>
  <van-dialog v-model:show="show"
              class="aboutUs-dialog"
              @closed="store.commit('setAboutUsDialogFlag', false)"
              :show-confirm-button="false"
              :close-on-click-overlay="true"
  >
    <van-image class="close-icon" :src="themeImgs.commonCloseIcon"
               @click="store.commit('setAboutUsDialogFlag', false)"/>

    <div class="title">{{ $t('关于我们') }}</div>
    <div class="content" v-html="handleContent(aboutContent)"></div>
  </van-dialog>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import {getAbout} from '@/api/api'
import {handleContent} from '@/utils'
import {useStore} from 'vuex'

const store = useStore()
// 请求数据接口
const handleGetAbout = () => {
  getAbout().then(res => {
    if (res.result == 200000) {
      aboutContent.value = res.data.info
    }
  })
}

onMounted(() => {
  handleGetAbout()
})

// 存储内容
const aboutContent = ref('')

const show = ref(false)

watch(() => store.state.aboutUsDialogFlag, val => {
  show.value = val
})

const themeImgs = computed(() => store.state.themeImgs)
</script>

<style lang='less' scoped>
.close-icon {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 24px;
  height: 24px;
}

.title {
  padding: 23px 0 28px;
  font-family: PingFang HK;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  color: #333;
}

.content {
  max-height: 378px;
  padding: 0 20px 40px;
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: #666666;
  text-indent: 2em;
  word-wrap: break-word;
  word-break: break-all;
  overflow: auto;
}
</style>

<style lang='less'>
.aboutUs-dialog {
  width: 327px;
  padding-bottom: 33px;
}
</style>
